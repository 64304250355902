<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenBtriService.training_info') }} {{ $t('globalTrans.reports') }}</h4>
      </template>
      <template v-slot:searchBody>
      <b-row>
        <b-col xs="12" sm="12" md="4">
          <ValidationProvider name="Event Type" vid="event_type" v-slot="{ errors }">
              <b-form-group
                  label-for="event_type"
              >
              <template v-slot:label>
                  {{ $t('teaGardenBtriService.event_type') }}
                  </template>
                  <b-form-select
                      plain
                      id="fiscal_year"
                      :options="eventTypeList"
                      v-model="search.event_type"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                  <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
              </b-form-group>
          </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="4">
            <ValidationProvider name="Event Type" vid="event_type" v-slot="{ errors }">
                <b-form-group
                    label-for="event_type"
                >
                <template v-slot:label>
                    {{ $t('teaGardenBtriService.event_name') }}
                    </template>
                    <b-form-select
                        plain
                        id="fiscal_year"
                        :options="eventList"
                        v-model="search.event_name"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0">{{ titleLoad ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                    </template>
                  </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="Date From" vid="date_from" v-slot="{ errors }">
                <b-form-group
                  label-for="date_from"
                >
                  <template v-slot:label>
                    {{ $t('globalTrans.date_from') }}
                  </template>
                  <date-picker
                    id="date_from"
                    class="form-control"
                    v-model="search.date_from"
                    :placeholder="$t('globalTrans.select')"
                  >
                  </date-picker>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="Date To" vid="date_to" v-slot="{ errors }">
                <b-form-group
                  label-for="date_to"
                >
                  <template v-slot:label>
                    {{ $t('globalTrans.date_to') }}
                  </template>
                  <date-picker
                    id="date_to"
                    class="form-control"
                    v-model="search.date_to"
                    :placeholder="$t('globalTrans.select')"
                  >
                  </date-picker>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
        <b-col sm="4">
          <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
            <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
          </b-button>
        </b-col>
      </b-row>
    </template>
      <!-- search section end -->
    </card>

    <b-row>
      <b-col md="12" v-if="showData">
        <body-card>
              <!-- table section start -->
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('teaGardenBtriService.training_info') }} {{ $t('globalTrans.reports') }}</h4>
              </template>
              <template v-slot:headerAction>
                <a href="javascript:" class="btn-add" @click="pdfExport">
                   {{ $t('globalTrans.export_pdf') }}
                </a>
              </template>
              <template v-slot:body>
                <b-overlay :show="loadingState">
                  <b-row>
                    <b-col>
                      <list-report-head :base-url="teaGardenServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="5" :service-id="23">
                        <template v-slot:projectNameSlot>
                          {{ }}
                        </template>
                        {{ $t('teaGardenBtriService.training_info') }} {{ $t('globalTrans.reports') }}
                      </list-report-head>
                    </b-col>
                  </b-row>
                  <template>
                    <div class="text-black mb-4">
                      <b-row>
                        <b-col md="8">
                          {{ $t('teaGardenBtriService.event_type') }}: <strong>{{ search.event_type ? ($i18n.locale === 'en' ? searchHeaderData.event_type_name_en : searchHeaderData.event_type_name_bn) : $t('globalTrans.all') }}</strong>
                        </b-col>
                        <b-col md="4">
                          {{ $t('teaGardenBtriService.event_name') }}: <strong>{{ search.event_name  ?  ($i18n.locale === 'en' ? searchHeaderData.event_name_en : searchHeaderData.event_name_bn) : $t('globalTrans.all') }}</strong>
                        </b-col>
                        <b-col md="8">
                          {{ $t('globalTrans.date_from') }}:
                          <strong>
                            <template v-if="search.date_from">
                              {{ search.date_from | dateFormat }}
                            </template>
                          </strong>
                        </b-col>
                        <b-col md="4">
                          {{ $t('globalTrans.date_to') }}:
                          <strong>
                            <template v-if="search.date_to">
                              {{ search.date_to | dateFormat }}
                            </template>
                          </strong>
                        </b-col>
                      </b-row>
                    </div>
                    <div>
                      <div class="table-responsive">
                        <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
                          <b-thead>
                            <b-tr>
                              <b-th style="width:10%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                              <b-th style="width:10%" class="text-center">{{ $t('teaGardenBtriService.schedule_id') }}</b-th>
                              <b-th style="width:10%" class="text-center">{{ $t('teaGardenBtriService.event_type') }}</b-th>
                              <b-th style="width:15%" class="text-center">{{ $t('teaGardenBtriService.event_name') }}</b-th>
                              <b-th style="width:15%" class="text-center">{{ $t('teaGardenBtriService.place') }}</b-th>
                              <b-th style="width:15%" class="text-center">{{ $t('globalTrans.date_from') }}</b-th>
                              <b-th style="width:15%" class="text-center">{{ $t('globalTrans.date_to') }}</b-th>
                              <b-th style="width:10%" class="text-center">{{ $t('globalTrans.status') }}</b-th>
                            </b-tr>
                          </b-thead>
                          <b-tbody>
                            <b-tr v-for="(item, index) in datas" :key="index">
                              <b-td class="text-center">{{ $n(index + 1) }} </b-td>
                              <b-td class="text-center">{{ item.schedule_id }}</b-td>
                              <b-td class="text-center">{{ getEventType(item.event_type) }}</b-td>
                              <b-td class="text-center">{{ getEventName(item) }}</b-td>
                              <b-td class="text-center">{{ ($i18n.locale === 'en') ? item.place_en : item.place_bn }}</b-td>
                              <b-td class="text-center">{{ item.date_from | dateFormat }}</b-td>
                              <b-td class="text-center">{{ item.date_to | dateFormat }}</b-td>
                              <b-td class="text-center">{{ checkWithCurrentDate(item.date_from, item.date_to) }} </b-td>
                            </b-tr>
                            <b-tr v-if="Object.keys(datas).length === 0">
                              <th colspan="8" class="text-center">{{ $t('globalTrans.no_data_found') }}</th>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </div>
                    </div>
                  </template>
                </b-overlay>
              </template>
              <!-- table section end -->
        </body-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { trainingReportApi, eventListApi } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import bazarMonitoringServiceMixin from '@/mixins/bazar-monitoring-service'
import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  mixins: [ModalBaseMasterList, bazarMonitoringServiceMixin],
  components: {
    ListReportHead
  },
  data () {
    return {
      titleLoad: false,
      eventList: [],
      valid: '',
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      search: {
        event_type: 0,
        event_name: 0,
        date_from: '',
        date_to: ''
      },
      datas: [],
      searchHeaderData: {
        event_type_name_en: '',
        event_type_name_bn: '',
        event_name_en: '',
        event_name_bn: ''
      },
      showData: false
    }
  },
  watch: {
    'search.event_type': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getEventList()
      }
  },
    currentLocale: function (newVal) {
      this.eventList = this.eventList.map(item => {
                return Object.assign({}, item, { text: newVal === 'en' ? item.text_en : item.text_bn })
            })
    }
  },
  computed: {
    statusList: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Pending' : 'অমীমাংসিত' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Circulated' : 'প্রচারিত' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Submitted' : 'জমাকৃত' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Certified' : 'প্রত্যয়িত' }
      ]
      return list
    },
    masterTeaGardenGenInfoList: function () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList
    },
    eventTypeList: function () {
      const list = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Training' : 'প্রশিক্ষণ' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Workshop' : 'কর্মশালা' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Course' : 'কোর্স' }
      ]
      return list
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    checkWithCurrentDate (dateFrom, dateTo) {
      // Set the current date
      const currentDate = new Date()

      // Set the date_from and date_to
      const dateFromNew = new Date(dateFrom)
      const dateToNew = new Date(dateTo)

      // Check if the current date is before, after, or between dateFromNew and dateToNew
      if (currentDate < dateFromNew) {
        return this.$t('globalTrans.upcoming')
      } else if (currentDate >= dateFromNew && currentDate <= dateToNew) {
        return this.$t('globalTrans.ongoing')
      } else {
        return this.$t('globalTrans.done')
      }
    },
    getStatusType (sta) {
      const reqStatusObj = this.statusList.find(tlaw => tlaw.value === parseInt(sta))
      return reqStatusObj.text
    },
    getEventList () {
      this.eventList = []
      this.titleLoad = true
      const params = Object.assign({}, { event_type: this.search.event_type })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(teaGardenServiceBaseUrl, eventListApi, params).then(result => {
          if (result.success) {
              this.titleLoad = false
              this.eventList = result.data.map(item => {
                  if (item.event_type === 1) {
                      item.text_en = item.training_name_en
                      item.text_bn = item.training_name_bn
                  } else if (item.event_type === 2) {
                      item.text_en = item.workshop_title_en
                      item.text_bn = item.workshop_title_bn
                  } else if (item.event_type === 3) {
                      item.text_en = item.course_name_en
                      item.text_bn = item.course_name_bn
                  }
              const tmp = this.$i18n.locale === 'en' ? { value: item.id, text: item.text_en } : { value: item.id, text: item.text_bn }
              return Object.assign({}, item, tmp)
              })
          } else {
              this.eventList = []
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getEventType (sta) {
      const reqStatusObj = this.eventTypeList.find(tlaw => tlaw.value === parseInt(sta))
      return reqStatusObj.text
    },
    getEventName (data) {
      if (data.event_type === 1) {
        return this.$i18n.locale === 'en' ? data.training_name_en : data.training_name_bn
      } else if (data.event_type === 2) {
        return this.$i18n.locale === 'en' ? data.workshop_title_en : data.workshop_title_bn
      } else if (data.event_type === 3) {
        return this.$i18n.locale === 'en' ? data.course_name_en : data.course_name_bn
      }
    },
    async pdfExport () {
      const eventName = this.$i18n.locale === 'en' ? this.searchHeaderData.event_name_en : this.searchHeaderData.event_name_bn
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, event_name_text: eventName }, this.search)
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 23)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, trainingReportApi, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      if (this.search.event_type) {
        const obj = this.eventTypeList.find(data => data.value === this.search.event_type)
        this.searchHeaderData.event_type_name_en = obj !== undefined ? obj.text : ''
        this.searchHeaderData.event_type_name_bn = obj !== undefined ? obj.text : ''
      }
      if (this.search.event_name) {
        if (this.eventList.length) {
          const obj = this.eventList.find(data => data.value === this.search.event_name)
          this.searchHeaderData.event_name_en = obj !== undefined ? obj.text : ''
          this.searchHeaderData.event_name_bn = obj !== undefined ? obj.text : ''
        }
      }
      if (this.search.garden_id) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(data => data.value === this.search.garden_id)
        this.searchHeaderData.garden_name_en = obj !== undefined ? obj.text_en : ''
        this.searchHeaderData.garden_name_bn = obj !== undefined ? obj.text_bn : ''
      }
      this.showData = true
      const params = Object.assign({}, this.search)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, trainingReportApi, params)
      if (result.success) {
        this.datas = result.data
        this.$store.dispatch('setList', this.datas)
      } else {
        this.$store.dispatch('setList', [])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
